@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

:root {
  --font-sans: "";
  -webkit-print-color-adjust: exact;

  color-adjust: exact;
}

:root {
  --background: 0 0% 100%; // white
  --foreground: 240 10% 3.9%; // bold black
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 15 100% 67%; // orange
  --primary-foreground: 355.7 100% 97.3%; // ultra very light gray
  --secondary: 240 4.8% 95.9%; // another ultra very light gray
  --secondary-foreground: 240 5.9% 10%; // black
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 220, 57.7%, 93.9%;
  --accent-foreground: 226, 100%, 37%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 221 100% 46%;
  --radius: 0.5rem;
  --hp-blue: 226, 100%, 37%;
}

.dark {
  --background: 20 14.3% 4.1%;
  --foreground: 0 0% 95%;
  --card: 24 9.8% 10%;
  --card-foreground: 0 0% 95%;
  --popover: 0 0% 9%;
  --popover-foreground: 0 0% 95%;
  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 97.3%;
  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;
  --muted: 0 0% 15%;
  --muted-foreground: 240 5% 64.9%;
  --accent: 12 6.5% 15.1%;
  --accent-foreground: 0 0% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;
  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;
  --ring: 346.8 77.2% 49.8%;
}

/* Additional font families */
.syne-normal {
  font-family: "Syne", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.__global_shadow_card_common__ {
  box-shadow:
    0px 1px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}

.__global_gradient_tmp__ {
  background: rgb(255, 101, 2);
  background: -moz-linear-gradient(162deg,
      rgba(255, 101, 2, 0.9318102240896359) 0%,
      rgba(40, 4, 236, 0.9374124649859944) 100%);
  background: -webkit-linear-gradient(162deg,
      rgba(255, 101, 2, 0.9318102240896359) 0%,
      rgba(40, 4, 236, 0.9374124649859944) 100%);
  background: linear-gradient(131deg, #ed6c43 35.45%, #0049ea 84.23%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff6502", endColorstr="#2804ec", GradientType=1);
}

.__global_gradient_header__ {
  background: linear-gradient(to bottom, #0049ea 20%, #00228e 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0049EA", endColorstr="#00228E", GradientType=1);
}

.__global_gradient_card_one__ {
  border-radius: 0.9375rem;
  background: linear-gradient(131deg, #ED6C43 16.39%, #0049EA 139.12%);

  /* Elevation/2 */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}
.__global_gradient_card_two__ {
  border-radius: 0.9375rem;
  background: var(--Gradientes-Gradiente-2, linear-gradient(176deg, #0049EA 18.31%, #00228E 80.39%));

  /* Elevation/2 */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}
.__global_gradient_card_three__ {
  border-radius: 0.9375rem;
  background: var(--Neutral-500, #8E8E8E);

  /* Elevation/2 */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}
body {
  background-color: #eeeeee;
}

/*

CSS Overidings

*/

.datepicker.datepicker-dropdown.dropdown {
  z-index: 999 !important;
}

.shimmer {
  background: linear-gradient(-45deg, rgba(210,210,210,1) 32%, rgba(222,222,222,1) 52%, rgba(210,210,210,1) 71%);
  background-size: 400%;
  background-position-x: 100%;
  animation: shimmer 1.5s infinite ease-in-out;
}

@keyframes shimmer {
  to {
    background-position-x: 0%
  }
}
